import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import { defaultTo, get, isEmpty } from 'lodash'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.mixin({
  methods: {
    defaultTo,
    get,
    isEmpty,
  },
})

app.use(ElementPlus)
app.use(router)
app.mount('#app')
