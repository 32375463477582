import { first } from 'lodash'
import { getDocs, query, where } from 'firebase/firestore'
import Crud from '@/services/Crud'

const subscribers = new Crud('Subscribers')
subscribers.readByEmail = async function (email) {
  if (!email) throw new Error('/services/subscribers::readByEmail/ missing email')

  try {
    const q = query(this.collection, where('email', '==', email))
    const qRef = await getDocs(q)
    if (!qRef.empty) {
      const doc = first(qRef.docs)
      return { id: doc.id, ...doc.data() }
    } else {
      throw new Error('No such document!')
    }
  } catch (error) {
    console.error('Error reading document', error)
    throw error
  }
}

export default subscribers
