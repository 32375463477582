<template>
  <MainTemplate>
    <SignUpForm />
  </MainTemplate>
</template>

<script>
import MainTemplate from '@/templates/MainTemplate.vue'
import SignUpForm from '@/components/SignUpForm.vue'

export default {
  name: 'SignUp',
  components: {
    MainTemplate,
    SignUpForm,
  },
}
</script>