<template>
  <NotFound v-if="loaded && !newsletter" />
  <NewsletterTemplate
    v-if="newsletter"
    :content="newsletter.content"
  />
</template>

<script>
import NewsletterTemplate from '@/templates/NewsletterTemplate.vue'
import NotFound from '@/views/NotFound'
import newsletters from '@/services/newsletters'

export default {
  name: 'Newsletter',
  components: {
    NewsletterTemplate,
    NotFound,
  },
  data() {
    return {
      loaded: false,
      newsletter: undefined,
      newsletterId: this.$route.params.newsletterId,
    }
  },
  async created() {
    try {
      const newsletter = await newsletters.read(this.newsletterId)
      this.newsletter = newsletter
    } catch (err) {
      console.error(`/Newsletter::created/ could not read newsletter with ID "${this.newsletterId}"`)
    } finally {
      this.loaded = true
    }
  },
}
</script>