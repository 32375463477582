<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0C2132;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0C2132;
}

.btn-primary {
  color: white;
}

.msg-pos-right {
  left: auto !important;
}

.page-heading {
  display: block;
  font-size: 3em;
  font-weight: bold;
  unicode-bidi: isolate;
}

.page-subheading {
  color: #C0C4C8;
}

.footer {
  /* position: absolute; */
  bottom: 20px;
  text-align: center;
  padding: 10px 0;
  background-color: #0C2132;
  color: #C0C4C8; /* Silver color */
  font-size: 14px;
}
</style>
