<template>
  <span class="sky-heading">Sky</span>
  <span class="fam-heading">Family</span>
</template>

<script>
export default {
  name: 'CompanyHeading',
}
</script>

<style scoped>
.sky-heading {
  background: linear-gradient(to bottom, #FF7E5F, #feb47b, #FF7E5F); /* Gradient colors resembling sunset */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fam-heading {
  color: #C0C4C8;
}
</style>