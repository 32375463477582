<template>
  <p class="description">
    At the heart of our mission lies the joy of family adventures.
    We lift the burdens of traveling with children, turning challenges into less turbulent, unforgettable journeys.
    With us, every trip becomes a canvas of memories, painted with laughter, discovery, and togetherness.
    <br><br>
    Please fasten your seatbelts and subscribe to our newsletter.
    Receive the latest family travel tips and be the first to participate in our exclusive family lounge pilot program.
  </p>

  <h3 class="page-subheading">Stay connected!</h3>

  <el-form :model="form" :rules="rules" ref="form" label-width="120px">
    <el-form-item label="First Name" prop="nameFirst">
      <el-input v-model="form.nameFirst" minlength="1" maxlength="255" />
    </el-form-item>

    <el-form-item label="Last Name" prop="nameLast">
      <el-input v-model="form.nameLast" minlength="1" maxlength="255" />
    </el-form-item>

    <el-form-item label="E-mail" prop="email">
      <el-input v-model="form.email" />
    </el-form-item>

    <el-form-item label="Role" prop="role">
      <el-radio-group v-model="form.role">
        <el-radio label="Father" value="father">Father</el-radio>
        <el-radio label="Mother" value="mother">Mother</el-radio>
        <el-radio label="Other" value="other">Other</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item v-if="form.role === 'other'" label="Specify" prop="roleOther">
      <el-input v-model="form.roleOther" maxlength="255" />
    </el-form-item>

    <el-form-item class="action-btns">
      <el-button class="btn-primary" color="#E48B38" type="primary" @click="submitForm('form')">Subscribe</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
const CHARS_MAXLEN = 255
import { escape } from 'lodash'
import { ElMessage } from 'element-plus'
import subscribers from '@/services/subscribers'

const FORM_INIT = () => ({
  nameFirst: '',
  nameLast: '',
  email: '',
  role: '',
  roleOther: '',
})

const validateEmail = (rule, value, callback) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(value)) {
    callback(new Error('Please enter a valid email address'));
  } else {
    callback()
  }
}

export default {
  name: 'SignUpForm',
  data() {
    return {
      form: FORM_INIT(),
      rules: {
        nameFirst: [
          { required: true, message: 'First name is required', trigger: 'blur' },
          { max: CHARS_MAXLEN, message: `Must be less than ${CHARS_MAXLEN} characters`, trigger: 'blur' },
        ],
        nameLast: [
          { required: true, message: 'Last name is required', trigger: 'blur' },
          { max: CHARS_MAXLEN, message: `Must be less than ${CHARS_MAXLEN} characters`, trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'E-mail is required', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' },
          { max: CHARS_MAXLEN, message: `Names must be less than ${CHARS_MAXLEN} characters`, trigger: 'blur' },
        ],
        role: [
          { required: false, message: 'Role is required', trigger: 'change' }
        ],
        roleOther: [
          { required: false, message: 'Please specify your role', trigger: 'blur' },
          { max: CHARS_MAXLEN, message: `Names must be less than ${CHARS_MAXLEN} characters`, trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    sanitizeFormData(form) {
      const sanitizedForm = {}
      for (const key in form) {
        if (form[key]) {
          sanitizedForm[key] = this.sanitizeInput(form[key])
        }
      }
      return sanitizedForm
    },
    sanitizeInput(value) {
      return escape(value)
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const form = this.sanitizeFormData(this.form)
          subscribers.create(form)
            .then(resp => {
              console.info('resp', resp)
              ElMessage.success({
                appendTo: this.$parent.$refs['message-placeholder'],
                customClass: 'msg-pos-right',
                message: 'Welcome to Sky Family!',
              })
            })
            .catch(err => {
              console.error('err: ', err)
              ElMessage.error({
                appendTo: this.$parent.$refs['message-placeholder'],
                customClass: 'msg-pos-right',
                message: 'Error submitting form. Try again later.',
              })
            })
            .finally(() => {
              this.form = FORM_INIT()
            })
        } else {
          ElMessage.error({
            appendTo: this.$parent.$refs['message-placeholder'],
            customClass: 'msg-pos-right',
            message: 'Please fill required fields',
          })
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
.description {
  padding: 10px;
  margin: 20px 0;
  text-align: left;
  font-size: 1.25em;
  color: #C0C4C8;
}

:deep() .el-form-item__label,
:deep() .el-radio__label {
  color: #C0C4C8 !important;
}

.el-form {
  width: 100%;
  max-width: 400px;
}
.el-form-item {
  width: 100%;
}

@media(min-width: 768px) {
  .description {
    padding: 30px 8rem;
    text-align: left;
    font-size: 1.25em;
  }

  .el-form {
    margin-right: 6rem;
  }
}
</style>