
<template>
  <MainTemplate>
    <h3>404 — Page not found</h3>
    <p>
      Whoops. May want to check that URL path again.
      <br>
      Can I interest you instead in signing up for our newsletters?
    </p>
    <router-link to="/newsletters/signup">
      <el-button>Sign-up page</el-button>
    </router-link>
  </MainTemplate>
</template>

<script>
import MainTemplate from '@/templates/MainTemplate'

export default {
  name: 'NotFound404',
  components: { MainTemplate },
}
</script>