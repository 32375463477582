import { createRouter, createWebHistory } from 'vue-router'
import Newsletter from '@/views/Newsletter.vue'
import SignUp from '@/views/SignUp.vue'
import NotFound from '@/views/NotFound.vue'
import subscribers from '@/services/subscribers'
import { isValidEmail } from '@/utils/emails'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/newsletters/signup',
  },
  {
    path: '/newsletters',
    name: 'Newsletters',
    redirect: '/newsletters/signup'
  },
  {
    path: '/newsletters/signup',
    name: 'NewslettersSignup',
    component: SignUp,
    meta: {
      title: 'Signup — SkyFamily Newsletter',
    }
  },
  {
    path: '/newsletters/:newsletterId',
    name: 'NewslettersRead',
    component: Newsletter,
    meta: {
      title: 'SkyFamily Newsletter',
    },
    props: (route) => ({
      newsletterId: route.params.newsletterId,
      user: route.params.user
    }),
    beforeEnter: async (to, from, next) => {
      const { email: userEmail } = to.query
      if (isValidEmail(userEmail)) {
        try {
          const user = await subscribers.readByEmail(userEmail)
          to.params.user = user
        } catch(err) {
          console.error(`/router/newsletters::beforeEnter/ could not retrieve user with email "${userEmail}`)
          // suppress err
        }
      }
      next()
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    redirect: '/newsletters/signup',
  },
  {
    path: '/:catchAll(.*)', // Catch all undefined routes
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '404 - SkyFamily Newsletter'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'SkyFamily'
  next()
})

export default router
