<template>
  <div class="container">
    <div class="image-section">
      <img src="@/assets/img/newsletter-cover.jpg" alt="Baby looking out airport window">
    </div>
    <div class="content-section">
      <div ref="message-placeholder" />
      <div class="content">
        <div class="page-heading">
          <CompanyHeading />
          <!-- <img src="@/assets/img/skyfam-logo.png" alt="SkyFamily Logo"> -->
        </div>
        <slot />
      </div>
      <footer class="footer">
        &copy; 2024 SkyFamily. All rights reserved.
      </footer>
    </div>
  </div>
</template>

<script>
import CompanyHeading from '@/components/CompanyHeading'
export default {
  name: 'SignUp',
  components: {
    CompanyHeading,
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.image-section {
  flex: 1;
  overflow: hidden;
}
.image-section img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  margin-top: -100px;
}

.content-section {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #F0F4F8;
}

.content-section .content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media(min-width: 768px) {
  .container {
    flex-direction: row;
  }

  .image-section img {
    height: 100%;
    margin-top: 0;
  }

  .content-section .description {
    padding: 30px 8rem;
    text-align: left;
    font-size: 1.25em;
  }

  .el-form {
    margin-right: 6rem;
  }

  .page-heading {
    font-size: 3em;
  }
}
</style>
