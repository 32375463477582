// src/services/firestoreService.js
import { db } from '../../../../firebase'
import { collection, addDoc, getDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore'

class Crud {
  constructor(collectionName) {
    this.collection = collection(db, collectionName)
  }

  async create(data) {
    try {
      const docRef = await addDoc(this.collection, data)
      return { id: docRef.id, ...data }
    } catch (error) {
      console.error('Error adding document: ', error)
      throw error
    }
  }

  async read(docId) {
    try {
      const docRef = doc(this.collection, docId)
      const thedoc = await getDoc(docRef)
      if (thedoc.exists()) {
        return { id: thedoc.id, ...thedoc.data() }
      } else {
        throw new Error('No such document!')
      }
    } catch (error) {
      console.error('Error reading document: ', error)
      throw error
    }
  }

  async update(docId, data) {
    try {
      const docRef = doc(this.collection, docId)
      await updateDoc(docRef, data)
      return { id: docId, ...data }
    } catch (error) {
      console.error('Error updating document: ', error)
      throw error
    }
  }

  async delete(docId) {
    try {
      const docRef = doc(this.collection, docId)
      await deleteDoc(docRef)
      return docId
    } catch (error) {
      console.error('Error deleting document: ', error)
      throw error
    }
  }
}

export default Crud
