/**
 * Checks if a string is a valid email address.
 * @param {string} str - The email string to validate.
 * @returns {boolean} - Returns true if the email is valid, otherwise false.
 */
export function isValidEmail(str) {
  if (!str) return false
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(str)
}

export default { isValidEmail }
