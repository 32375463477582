<template>
  <div class="container">
    <div class="header">
      <h1>
        <CompanyHeading />
        <br>Newsletter
      </h1>
    </div>
    <div class="content">
      <p>Dear {{ defaultTo(user.nameFirst, 'SkyFamily member') }},</p>
      <p>Rejoice, for you are SkyFamily! We have some exciting news and updates for you.</p>
      <p><strong>News:</strong></p>
      <div v-html="getMarkedContent()"></div>
    </div>
    <div class="action-btns">
      <router-link :to="`https://skyfamily.net/newsletters/${newsletterId}`">
        <el-button class="btn-primary" color="#E48B38" type="primary">Read more</el-button>
      </router-link>
    </div>
    <div class="footer">
      <p>&copy; 2024 SkyFamily. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it';
import CompanyHeading from '@/components/CompanyHeading.vue'

const md = new MarkdownIt()

export default {
  name: 'NewsletterTemplate',
  components: {
    CompanyHeading,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    newsletterId: {
      type: String,
      default: '0',
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getMarkedContent() {
      if (!this.content) return ''
      return md.render(this.content)
    },
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}
.header {
  text-align: center;
  padding: 20px 0;
}
.content {
  font-size: 16px;
  line-height: 1.6;
}

.action-btns {
  text-align: center;
  margin-bottom: 20px;
}
.footer {
  text-align: center;
  padding: 20px 0;
  font-size: 12px;
  background-color: #fff;
  color: #777;
}
</style>
